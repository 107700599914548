<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contentBox">
        <div class="flex title ju-between">
          <div class="m-t1">{{ item.title }}</div>
          <div>
            <span v-if="item.num" class="cur-p" @click="open(null,item)">
              <CountTo color="#0066CC" fontSize="18px" :endVal="item.num" />
            </span>
          </div>
        </div>
        <div>
          <div v-if="item.subTitle" class="tips">
            {{ item.subTitle }}
          </div>
          <div class="flex wrap">
            <div v-for="opt in item.subItemList" :key="opt.id" class=" m-r2 item">
              {{ opt.title }}
              <span>
                <span @click="open(opt,item)" class="cur-p" :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>
              </span>
              {{ opt.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" ref="TableRef" :title="title" @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk" @success="success" @searchChange="searchChange" :colorIdx="colorIdx" :item="itemRemake" @changeBtn="changeBtn" :loading="loading" :pagination="pagination" @addFollowNew="addFollow" :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" keyType="pageType" @changePagination="changePagination" :isOrder="isOrder" :iptData="iptData" @download="downloads" :columns="columns" :data="dataSource" :totalClueNum="totalClueNum" :subItemList="subItemListData" :isAll="isAll">
      </Table>
    </template>

  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { colorType } from '@/utils/enum.js'
import CountTo from '@/components/countTo'
import Table from '../table'
import { documentaryPage } from '@/api/transport/documentary'
import { employeeList } from '@/api/crmManagement/setRules'
import { IfNull, debounce } from '@/utils/util'
import {
  orderiptData1, orderiptData2, ordercolumns2, orderiptData3, ordercolumns3, orderiptData4, ordercolumns4, orderiptData41, ordercolumns41,
  orderiptData5, ordercolumns5, orderiptData6, ordercolumns6, ordercolumns61, orderiptData7, ordercolumns7, ordercolumns71
} from './keyIssues'
import { downloadContractList, downloadInsuranceList, customerContractList, vehicleInsuranceNoList, expenseReportList, vehicleAnalysisList, luxuryCarInsuranceList, waitLabel } from '@/api/businessCenter/businessCenter'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
})
const TableRef = ref(null)
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const itemRemake = ref({})
const loading = ref(false)
const data = ref([])
const iptData = ref([])
const columns = ref([])
const dataSource = ref([])
const subItemListData = ref([])
const totalClueNum = ref(0)
const isOrder = ref(false)
const isAll = ref(true)
const pageSize = ref(50)
const getUrl = ref(null)
const recordType = ref({})
const searchMsg = ref({})
const uniqueArrList = ref([])
const state = reactive({
  timer: null
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showTotal: (total, range) => `共 ${total} 条`
})

const searchChange = (e) => {
  if (!IfNull(e)) return
  debounce(state, getEmployeeList, 1000, e)
}
const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      iptData.value.forEach(opt => {
        if (opt.prop === 'saleId') {
          opt.opt = list
        }
      })
    }
  })
}
const resetBtn = () => {
  searchMsg.value = {}
  if (itemRemake.value.pageType === 100) {
    pagination.value.current = 1
    loadData(recordType.value.pageType, recordType.value.colorType)
    return
  }
  pageSize.value = 50
  getData(recordType.value.pageType, recordType.value.colorType)
}
const searchAdd = (e) => {
  searchMsg.value = e
  if (itemRemake.value.pageType === 100) {
    pagination.value.current = 1
    loadData(recordType.value.pageType, recordType.value.colorType)
    return
  }
  pageSize.value = 50
  getData(recordType.value.pageType, recordType.value.colorType)
}
//  关注     
const addFollow = (item, type) => {
  loadData(recordType.value.pageType, recordType.value.colorType)
}
const changePagination = (e) => {
  pagination.value.current = e
  loadData(recordType.value.pageType, recordType.value.colorType)
}
const success = (e, type) => {
  data.value.forEach((item, index) => {
    if (item[type] === e) {
      data.value.splice(index, 1)
      dataSource.value.splice(index, 1)
    }
  })
}
const setRemaekOk = () => {

}
const downloads = (e) => {
  data.value.forEach((item, index) => {
    if (item.orderId === e) {
      data.value.splice(index, 1)
    }
  })
}
const changeBtn = (e, v) => {
  if (v === 100) {
    pagination.value.current = 1
    loadData(v, e)
    return
  }
  if (v === 4) {
    if (e === 401) {
      iptData.value = orderiptData4.value
      columns.value = ordercolumns4.value
      getUrl.value = expenseReportList
    }
    if (e === 402) {
      iptData.value = orderiptData41.value
      columns.value = ordercolumns41.value
      getUrl.value = vehicleAnalysisList
    }
    setOpt()
  }
  if (v === 6) {
    if (e === 62) {
      iptData.value = orderiptData6.value
      columns.value = ordercolumns6.value
      x.value = 0
    }
    if (e === 63) {
      iptData.value = orderiptData6.value
      columns.value = ordercolumns61.value
      x.value = 2000
    }
    pageSize.value = 50
    searchMsg.value = {}
    TableRef.value.SearchListRef.setValue()
    uniqueArrList.value = []
    setOpt()
  }
  if (v === 7) {
    if (e === 702) {
      columns.value = ordercolumns7.value
      getUrl.value = vehicleInsuranceNoList
    }
    if (e === 701) {
      columns.value = ordercolumns71.value
      getUrl.value = luxuryCarInsuranceList
    }
    setOpt()
  }
  getData(v, e)
}

const open = (opt, item) => {
  searchMsg.value = {}
  isAll.value = true
  if (item.subItemList) {
    subItemListData.value = item.subItemList
  } else {
    subItemListData.value = []
  }
  totalClueNum.value = item.num
  if (!opt) {
    colorIdx.value = null
  } else {
    colorIdx.value = opt.pageType
  }
  if (item.pageType === 4 || item.pageType === 6 || item.pageType === 7) {
    isAll.value = false
  }
  itemRemake.value = item
  let colorType = opt ? opt.pageType : null
  processingData(item.pageType, colorType)
  title.value = item.title
  visible.value = true
}
const processingData = (pageType, colorType) => {
  if (pageType === 100) {
    iptData.value = orderiptData1.value
    isOrder.value = true
    x.value = 0
    dataSource.value = []
    pagination.value.current = 1
    loadData(pageType, colorType)
    setOpt()
    return
  }
  isOrder.value = false
  switch (pageType) {
    case 2:
      iptData.value = orderiptData2.value
      columns.value = ordercolumns2.value
      x.value = 0
      getUrl.value = downloadInsuranceList
      break;
    case 3:
      iptData.value = orderiptData3.value
      columns.value = ordercolumns3.value
      x.value = 0
      getUrl.value = downloadContractList
      break;
    case 4:
      if (colorType === 401) {
        iptData.value = orderiptData4.value
        columns.value = ordercolumns4.value
        getUrl.value = expenseReportList
      }
      if (colorType === 402) {
        iptData.value = orderiptData41.value
        columns.value = ordercolumns41.value
        getUrl.value = vehicleAnalysisList
      }
      x.value = 0
      break;
    case 5:
      iptData.value = orderiptData5.value
      columns.value = ordercolumns5.value
      x.value = 0
      getUrl.value = customerContractList
      break;
    case 6:
      if (colorType === 62) {
        iptData.value = orderiptData6.value
        columns.value = ordercolumns6.value
        x.value = 0
      }
      if (colorType === 63) {
        iptData.value = orderiptData6.value
        columns.value = ordercolumns61.value
        x.value = 2000
      }
   
      getUrl.value = waitLabel
      break;
    case 7:
      iptData.value = orderiptData7.value
      x.value = 0
      if (colorType === 702) {
        columns.value = ordercolumns7.value
        getUrl.value = vehicleInsuranceNoList
      }
      if (colorType === 701) {
        columns.value = ordercolumns71.value
        getUrl.value = luxuryCarInsuranceList
      }
      break;
  }
  setOpt()
  getData(pageType, colorType)
}
const getData = (pageType, colorType) => {
  if (getUrl.value) {
    pageSize.value = 50
    loading.value = true
    dataSource.value = []
    getUrl.value({
      ...searchMsg.value,
      subType: colorType || pageType
    }).then(res => {
      if (res.code !== 10000) return
      recordType.value.pageType = pageType
      recordType.value.colorType = colorType
      data.value = res.data
      dataSource.value = data.value.slice(0, pageSize.value)
      if (pageType === 6) {
        let list = []
        data.value.forEach(item => {
          list.push({
            name: item?.orgName,
            id: item?.orgId
          })
        })
        const uniqueArr = list.filter((item, index) => list.findIndex(i => i.id === item.id) === index);
        if(uniqueArr<=uniqueArrList.value) return
        uniqueArrList.value = uniqueArr
        iptData.value.forEach(item => {
          if (item.prop === 'orgIds') {
            item.opt = uniqueArr
          }
        })
      }
    }).finally(() => {
      loading.value = false
    })
  }
}
const setOpt = () => {
  iptData.value.forEach(item => {
    if (item.prop === 'orderSettlement') {
      item.opt = store.state.enumAll.orderSettlementList
    }
    if (item.prop === 'orgId') {
      item.opt = store.state.app.orgBusList
    }
    if (item.prop === 'exceptionType') {
      item.opt = store.state.enumAll.exceptionExplain
    }
    if (item.prop === 'saleId') {
      item.opt = []
    }
    if (item.prop === 'intentionLevel') {
      item.opt = store.state.app.labelAll.INTENTION_LEVEL
    }
    if (item.prop === 'settlementMode') {
      item.opt = store.state.enumAll.CustomerSettlementEnum
    }
  })
}
const loadData = (pageType, colorType) => {
  loading.value = true
  documentaryPage({
    subType: colorType || pageType,
    current: pagination.value.current,
    size: pagination.value.pageSize,
    ...searchMsg.value
  }).then(res => {
    if (res.code === 10000) {
      recordType.value.pageType = pageType
      recordType.value.colorType = colorType
      dataSource.value = res.data.records
      // isDeliveryCard === 1   orderStatus === 9
      dataSource.value.forEach(item => {
        if (item.isDeliveryCard && item.isDeliveryCard.value === 1 && item.orderStatus.value === 9) item.show = false
        else item.show = true
      })
      pagination.value.total = res.data.total
    }
  }).finally(() => { loading.value = false })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
</script>

<style lang="less" scoped>
.contentBox {
  margin-top: 10px;
  padding: 10px;
  background: #edf0f9;
  border-radius: 4px;
}

.title {
  color: #333;
  font-size: 13px;
}

.tips {
  color: #999;
  font-size: 12px;
}

.item {
  color: #344563;
  font-size: 12px;
}
</style>