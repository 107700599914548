<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contenBox">
        <div class="title flex ju-between al-center">
          <div>
            <span>{{ item.title }}</span>
            <span class="cur-p" v-if="item.num" @click="open(null,item)">
              <CountTo color="#0066CC" fontSize="14px" :endVal="item.num" />
            </span>
          </div>
          <div v-if="item.define">
            <a-tooltip>
              <template #title>
                <div v-html="item.define"></div>
              </template>
              <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
            </a-tooltip>
          </div>
        </div>
        <div v-if="item.subItemList" class="flex ju-between wrap" style="padding: 0px 10px 0px 10px">
          <div v-for="opt in item.subItemList" @click="open(opt,item)" :key="opt.id" class="itemBox m-b1 flex ju-between" style="width: 49%">
            {{ opt.title }}
            <a>{{ opt.num }}</a>
          </div>
        </div>
        <div class="tips" v-if="item.subTitle">
          {{item.subTitle}}
        </div>
        <div v-if="item.buttonList" class="flex" style="padding: 0px 0px 10px 10px">
          <div v-for="btn in item.buttonList" :key="btn.id" class="buttonBox m-r1">
            <a-button type="primary" size="small">
              {{ btn.label }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" :title="title" @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk" :colorIdx="colorIdx" :pagination="pagination" @changeBtn="changeBtn" :loading="loading" :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" keyType="pageType" :isAll="isAll" @changePagination="changePagination" :item="itemRemake" :totalClueNum="totalClueNum" :iptData="iptData" :columns="columns" :data="dataSource" :subItemList="subItemListData" :isOrder="isOrder" @addFollowNew="addFollow">
        <template #searchTabel>
          <div v-if="iptData.length === 0" class="iptBox flex">
            <a-select show-search v-model:value="selectVal" placeholder="客户名称" style="width: 200px" :options="customerNameOpt"></a-select>
            <a-input style="width: 200px;margin-left:10px" v-model:value="orderId" placeholder="订单号"></a-input>
            <a-input style="width: 200px;margin-left:10px" v-model:value="vinNo" placeholder="车架号"></a-input>
            <a-button type="primary" @click="searchCus" style="margin-left:10px">搜索</a-button>
            <a-button @click="resetCus" style="margin-left:10px">重置</a-button>
          </div>
        </template>
      </Table>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CountTo from '@/components/countTo'
import Table from '../table'
import { orderiptData1, orderiptData2, vehicleiptData1, vehiclecolumns1, vehiclecolumns11, vehiclecolumns2, vehiclecolumns21, vehicleiptData3, vehiclecolumns3, vehicleiptData5, vehiclecolumns5 } from './order'
import { documentaryPage } from '@/api/transport/documentary'
import { useStore } from 'vuex'
import { commissionVehicle, takeoutVehicle, transportVehicle, planVehicle, arrivedVehicle } from '@/api/businessCenter/businessCenter'
import { cloneDeep } from 'lodash-es'
const store = useStore()
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
  styleType: {
    type: Number,
    default: null
  }
})
const selectVal = ref(null)
const orderId = ref('')
const vinNo = ref('')
const customerNameOpt = ref([])
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const loading = ref(false)
const data = ref([])
const dataSource = ref([])
const locaData = ref([])
const subItemListData = ref([])
const totalClueNum = ref(0)
const iptData = ref([])
const columns = ref([])
const isAll = ref(true)
const isOrder = ref(true)
const recordType = ref({})
const itemRemake = ref({})
const getUrl = ref(null)
const searchMsg = ref({})
const pageSize = ref(50)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showTotal: (total, range) => `共 ${total} 条`
})

const searchCus = () => {
  pageSize.value = 50
  if (selectVal.value) {
    data.value = locaData.value.filter(item => {
      return item.customerName == selectVal.value
    })
  }else{
    data.value = locaData.value
  }
 if (orderId.value) {
    data.value = data.value.filter(item => {
      return item.orderId == orderId.value
    })
  }
 if (vinNo.value) {
    data.value = data.value.filter(item => {
      return item.vinNo == vinNo.value
    })
  }
  dataSource.value = data.value.slice(0, pageSize.value)
}
const resetCus = () => {
  selectVal.value = null
  orderId.value = ''
  vinNo.value = ''
  pageSize.value = 50
  data.value =locaData.value
  dataSource.value = data.value.slice(0, pageSize.value)
}

const resetBtn = () => {
  searchMsg.value = {}
  if (props.styleType === 4) {
    pagination.value.current = 1
    loadData(recordType.value.pageType, recordType.value.colorType)
  }
  if (props.styleType === 5) {
    getData(recordType.value.pageType, recordType.value.colorType)
  }
}
const searchAdd = (e) => {
  searchMsg.value = e
  if (props.styleType === 4) {
    pagination.value.current = 1
    loadData(recordType.value.pageType, recordType.value.colorType)
  }
  if (props.styleType === 5) {
    getData(recordType.value.pageType, recordType.value.colorType)
  }
}
//  关注     
const addFollow = () => {
  loadData(recordType.value.pageType, recordType.value.colorType)
}
const changePagination = (e) => {
  pagination.value.current = e
  loadData(recordType.value.pageType, recordType.value.colorType)
}
const changeBtn = (e, v) => {
  if (props.styleType === 4) {
    pagination.value.current = 1
    loadData(v, e)
  }
  if (props.styleType === 5) {
    if (e === 521) {
      columns.value = vehiclecolumns2.value
      getUrl.value = transportVehicle
    }
    if (e === 522) {
      columns.value = vehiclecolumns21.value
      getUrl.value = planVehicle
    }
    if (v === 51) {
      if (e === 511) {
        columns.value = vehiclecolumns1.value
      } else {
        columns.value = vehiclecolumns11.value
      }
    }
    getData(v, e)
  }
}
const open = (opt, item) => {
  searchMsg.value = {}
  isAll.value = false
  if (item.subItemList) {
    subItemListData.value = item.subItemList
    totalClueNum.value = item.num
  } else {
    subItemListData.value = []
  }
  itemRemake.value = item
  if (props.styleType === 4) {
    isOrder.value = true
    if (item.pageType === 42) {
      isAll.value = true
      iptData.value = orderiptData1.value
    }
    if (item.pageType === 43) {
      iptData.value = orderiptData2.value
    }
    if (opt) {
      colorIdx.value = opt.pageType
    } else {
      colorIdx.value = null
    }
    let colorType = opt ? opt.pageType : null
    pagination.value.current = 1
    loadData(item.pageType, colorType)
  }
  if (props.styleType === 5) {
    if (opt) {
      colorIdx.value = opt.pageType
    } else {
      colorIdx.value = null
    }
    let colorType = opt ? opt.pageType : null
    processingData(item.pageType, colorType)
  }
  setOpt()
  title.value = item.title
  visible.value = true
  console.log(item);
}
const processingData = (pageType, colorType) => {
  isOrder.value = false
  switch (pageType) {
    case 51:
      if (colorType === 511) {
        columns.value = vehiclecolumns1.value
      } else {
        columns.value = vehiclecolumns11.value
      }
      isAll.value = true
      iptData.value = vehicleiptData1.value
      x.value = 0
      getUrl.value = commissionVehicle
      break;
    case 52:
      if (colorType === 521) {
        iptData.value = []
        columns.value = vehiclecolumns2.value
        x.value = 0
        getUrl.value = transportVehicle
      }
      if (colorType === 522) {
        iptData.value = []
        columns.value = vehiclecolumns21.value
        x.value = 0
        getUrl.value = planVehicle
      }
      break;
    case 53:
      iptData.value = vehicleiptData3.value
      columns.value = vehiclecolumns3.value
      x.value = 0
      getUrl.value = takeoutVehicle
      break;
    case 55:
      iptData.value = vehicleiptData5.value
      columns.value = vehiclecolumns5.value
      x.value = 0
      getUrl.value = arrivedVehicle
      break;
  }

  getData(pageType, colorType)
}
const getData = (pageType, colorType) => {
  if (getUrl.value) {
    selectVal.value = null
     orderId.value = ''
     vinNo.value = ''
    pageSize.value = 50
    loading.value = true
    dataSource.value = []
    getUrl.value({
      ...searchMsg.value,
      subType: colorType || pageType
    }).then(res => {
      if (res.code !== 10000) return
      recordType.value.pageType = pageType
      recordType.value.colorType = colorType
      data.value = res.data
      locaData.value = cloneDeep(res.data)
      let list = locaData.value.map(item => {
        return {
          label: item?.customerName,
          value: item?.customerName
        }
      })
      customerNameOpt.value = []
      customerNameOpt.value.push(...list)
      customerNameOpt.value = uniqueBy(customerNameOpt.value, item => item.value)
      dataSource.value = data.value.slice(0, pageSize.value)
    }).finally(() => {
      loading.value = false
    })
  }
}
const uniqueBy = (arr, key) => {
  const seen = new Set();
  return arr.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};
const loadData = (pageType, colorType) => {
  loading.value = true
  documentaryPage({
    subType: colorType || pageType,
    current: pagination.value.current,
    size: pagination.value.pageSize,
    ...searchMsg.value
  }).then(res => {
    if (res.code === 10000) {
      recordType.value.pageType = pageType
      recordType.value.colorType = colorType
      dataSource.value = res.data.records
      dataSource.value.forEach(item => {
        if (item.isDeliveryCard && item.isDeliveryCard.value === 1 && item.orderStatus.value === 9) item.show = false
        else item.show = true
      })
      pagination.value.total = res.data.total
    }
  }).finally(() => { loading.value = false })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const setOpt = () => {
  iptData.value.forEach(item => {
    if (item.prop === 'orderSettlement') {
      item.opt = store.state.enumAll.orderSettlementList
    }
    if (item.prop === 'orgId') {
      item.opt = store.state.app.orgBusList
    }
  })
} 
</script>

<style lang="less" scoped>
.contenBox {
  margin-top: 10px;
  background: #f7f8fc;
  border-radius: 4px;
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
}

.itemBox {
  padding: 2px 5px;
  background: #edf0f9;
  border-radius: 2px;
  font-size: 13px;
  color: #344563;
}

.tips {
  padding: 10px;
  border-top: 1px solid #fff;
  color: #999;
  font-size: 12px;
}
.tipsIcon {
  width: 15px;
  height: 15px;
}
.iptBox {
  padding: 10px;
  background: #fff;
}
</style>