<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contenBox" v-if="item.subItemList">
        <div class="title flex ju-between">
          <div>
            <span>{{ item.title }}</span>
            <span v-if="item.num" class="cur-p" @click="open(null,item)">
              <CountTo color="#0066CC" fontSize="14px" :endVal="item.num" />
            </span>
          </div>
          <div v-if="item.define">
            <a-tooltip>
              <template #title>
                <div v-html="item.define"></div>
              </template>
              <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
            </a-tooltip>
          </div>
        </div>
        <div v-if="item.subItemList" class="flex ju-between wrap" style="padding: 0px 10px 0px 10px">
          <div v-for="opt in item.subItemList" @click="open(opt,item)" :key="opt.id" class="itemBox m-b1 flex ju-between" style="width: 49%">
            {{ opt.title }}
            <a>{{ opt.num }}</a>
          </div>
        </div>
      </div>
      <div v-else class="info">
        {{ item.title }}：{{ item.num }}
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" :title="title" :pagination="pagination" @changePagination="changePagination" :styleType="styleType"
       @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk" :colorIdx="colorIdx"   keyType="pageType" @searchChange="searchChange"
       :item="itemRemake" @changeBtn="changeBtn" :loading="loading" :dataAmt="dataAmt" :dataLength="data.length" @success="success"
        @searchAdd="searchAdd" @resetBtn="resetBtn" :isAll="isAll" :totalClueNum="totalClueNum" @addFollowNew="addFollow"
         :iptData="iptData" :columns="columns" :data="dataSource" :subItemList="subItemListData" 
         :isOrder="isOrder">
      </Table>
    </template>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import CountTo from '@/components/countTo'
import Table from '../table'
import { documentaryPage } from '@/api/transport/documentary'
import { orderiptData1, ordercolumns1, orderiptData2, orderiptData3, ordercolumns3, orderiptData4, ordercolumns4,  } from './myAttention'
import { receivableDetails, focusCustomer } from '@/api/businessCenter/businessCenter'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { employeeList } from '@/api/crmManagement/setRules'
import { IfNull, debounce , compute} from '@/utils/util'
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
  styleType:{
    type: Number,
    default: null
  },
})
const store = useStore()
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const loading = ref(false)
const data = ref([])
const dataAmt = ref(0)
const dataSource = ref([])
const iptData = ref([])
const columns = ref([])
const subItemListData = ref([])
const totalClueNum = ref(0)
const isAll = ref(true)
const isOrder = ref(false)
const recordType = ref({})
const pageSize  = ref(50)
const itemRemake = ref({})
const searchMsg = ref({})
const remakeType = ref(null)
const state= reactive({
  timer:null
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showTotal: (total, range) => `共 ${total} 条`
})

const searchAdd = (e) =>{
 searchMsg.value = e
 pageSize.value = 50
 if(remakeType.value === 132){
  getFocusCustomer()
 }
 if(remakeType.value === 133){
  pagination.value.current = 1
  loadData(remakeType.value)
 }
 if(remakeType.value === 141 || remakeType.value === 142 || remakeType.value === null){
  getReceivableDetails( recordType.value.pageType, recordType.value.itemType)
 }
}
const resetBtn = (e) =>{
 searchMsg.value = {}
 pageSize.value = 50
 if(remakeType.value === 132){
  getFocusCustomer()
 }
 if(remakeType.value === 133){
  pagination.value.current = 1
  loadData(remakeType.value)
 }
 if(remakeType.value === 141 || remakeType.value === 142 || remakeType.value === null){
  getReceivableDetails( recordType.value.pageType, recordType.value.itemType)
 }
}
const searchChange = (e) => {
  if(!IfNull(e)) return
  debounce(state,getEmployeeList,1000,e)
}
const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      iptData.value.forEach( opt =>{
        if(opt.prop === 'saleId'){
          opt.opt = list
        }
      })
    }
  })
}
const success = (e, type) => {
  data.value.forEach((item, index) => {
    if (item[type] === e) {
      data.value.splice(index, 1)
      dataSource.value.splice(index, 1)
    }
  })
}
const addFollow = () => {
  loadData(recordType.value.pageType)
}
const changePagination = (e) => {
  pagination.value.current = e
  loadData(recordType.value.pageType)
}
const changeBtn = (e,v) => {
  dataSource.value = []
  if(props.styleType===13){
  searchMsg.value = {}
  }
  pageSize.value = 50
 processingData(e,v)
}
const open = (opt, item) => {
  isAll.value = true
  searchMsg.value = {}
  if (item.subItemList) {
    subItemListData.value = item.subItemList
  } else {
    subItemListData.value = []
  }
  totalClueNum.value = item.num
  if (!opt) {
    colorIdx.value = null
  } else {
    colorIdx.value = opt.pageType
    if (opt.pageType === 132 || opt.pageType === 133 || opt.pageType === 134) {
      isAll.value = false
    }
  }
  itemRemake.value = item
  let pageType = opt ? opt.pageType : null
  processingData(pageType,item.pageType)
  remakeType.value = pageType
  setOpt()
  title.value = item.title
  visible.value = true
}
const processingData = (pageType,itemType) => {
  isOrder.value = false
  switch (pageType) {
    case 132:
      iptData.value = orderiptData1.value
      columns.value = ordercolumns1.value
      x.value = 0
      getFocusCustomer()
      break;
    case 133:
      iptData.value = orderiptData2.value
      isOrder.value = true
      x.value = 0
      pagination.value.current = 1
      loadData(pageType)
      break;
    case 134:
      iptData.value = orderiptData3.value
      columns.value = ordercolumns3.value
      x.value = 0
      break;
    case 141:
      iptData.value = orderiptData4.value
      columns.value = ordercolumns4.value
      x.value = 0
      getReceivableDetails(pageType,itemType)
      break;
    case 142:
      iptData.value = orderiptData4.value
      columns.value = ordercolumns4.value
      x.value = 0
      getReceivableDetails(pageType,itemType)
      break;
    case null:
     if(isAll.value){
      iptData.value = orderiptData4.value
      columns.value = ordercolumns4.value
      x.value = 0
      getReceivableDetails(pageType,itemType)
     }
      break;

  }
}
const getFocusCustomer = () => {
  loading.value = true
  focusCustomer({
    ...searchMsg.value
  }).then(res => {
    if(res.code !== 10000) return
     data.value = res.data
     dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => { loading.value = false })
}
const getReceivableDetails = (pageType,itemType) => {
  loading.value = true
  receivableDetails({
    subType: pageType || itemType,
    ...searchMsg.value
  }).then( res => {
    if(res.code !== 10000) return
    recordType.value.pageType = pageType
    recordType.value.itemType = itemType
     data.value = res.data
     let list = res.data.map(item => item.settableAmt)
     if(list.length>0){
      dataAmt.value = compute('+',...list).result
     }
     dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => { loading.value = false })
}
const loadData = (pageType) => {
  loading.value = true
  documentaryPage({
    subType:  pageType,
    current: pagination.value.current,
    size: pagination.value.pageSize,
    ...searchMsg.value
  }).then(res => {
    if (res.code === 10000) {
      recordType.value.pageType = pageType
      dataSource.value = res.data.records
      dataSource.value.forEach(item => {
        if (item.isDeliveryCard && item.isDeliveryCard.value === 1 && item.orderStatus.value === 9) item.show = false
        else item.show = true
      })
      pagination.value.total = res.data.total
    }
  }).finally(() => { loading.value = false })
}
const setOpt = () => {
  iptData.value.forEach(item => {
    if (item.prop === 'orderSettlement') {
      item.opt = store.state.enumAll.orderSettlementList
    }
    if (item.prop === 'orgId') {
      item.opt = store.state.app.orgBusList
    }
    if (item.prop === 'exceptionType') {
      item.opt = store.state.enumAll.exceptionExplain
    }
    if(item.prop === 'saleId'){
      item.opt = []
    }
    if(item.prop === 'intentionLevel'){
      item.opt = store.state.app.labelAll.INTENTION_LEVEL_DIS
    }
    if (item.prop === 'settlementMode') {
      item.opt = store.state.enumAll.CustomerSettlementEnum
    }
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
  </script>
  
  <style lang="less" scoped>
.contenBox {
  margin-top: 10px;
  background: #f7f8fc;
  border-radius: 4px;
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
}

.itemBox {
  padding: 2px 5px;
  background: #edf0f9;
  border-radius: 2px;
  color: #344563;
}

.tips {
  padding: 10px;
  border-top: 1px solid #fff;
  color: #999;
  font-size: 12px;
}
.info {
  padding: 10px;
  padding-bottom: 0;
  color: #6b778c;
}
.tipsIcon {
  width: 15px;
  height: 15px;
}
</style>